.App {
  font-family: sans-serif;
  text-align: center;
}

.navbar {
  background-color: #202020;

  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  height: 50%;
}

#mainicon.navbar {
  margin-right: auto;
}

#button.navbar {
  color: black;
  border-style: solid;
  border-width: 1px;
  border-color: #606060;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  background-color: #404040;
  text-decoration: none;
}

#button.navbar:hover {
  background-color: #303030;
}

.navbar input[type="text"] {
  float: right;
  padding: 6px;
  border: none;
  --margin-top: 8px;
  margin-left: 16px;
  font-size: 17px;
  background: #ccc;
}

#signindiv {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
}

.center {
  text-align: center;
}

.tinytextleft {
  align-self: flex-start;
  margin: 0px;
}
.inputbox {
  width: 60%;
  height: 40%;
  padding: 12px 20px;
  margin: 0px;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Dropdown Button */
.dropbtn {
  background-color: #3a3a3a;
  color: white;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #535353;
}

#mainImage {
  border: solid;
}
